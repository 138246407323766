/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.background-primary {
  // background-color: #fafafa;
  background-color: #f2f6fa;
}

.background-secondary {
  background-color: #fff;
}
